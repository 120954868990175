<template>
  <header class="w-full z-10">
    <Navigation /> 
  </header>
  <main>
    <router-view v-slot="{ Component }" class="min-h-screen text-white">
      <transition name="scale" mode="out-in">
        <component :is="Component" :key="$route.path"/>
      </transition>
    </router-view>
  </main>
  <footer class="z-10 bg-gray-800 w-full text-white">
     <Footer/>
  </footer>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  mounted() {
  },
}
</script>

<style>
body {
  margin: 0;
  background: radial-gradient(circle at 0 2%, #1F2937, #314158 99%);
  color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.65s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
