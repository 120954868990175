<template>
    <div>
        <div class="flex h-screen">
            <div ref="background1" class="background1 w-full flex items-center justify-center text-black">
                <h1 style="clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);" class="title font-bold text-4xl absolute top-1/4">Kuźnia Raciborska</h1>
            </div>
        </div>
        <div class="container min-h-screen text-black flex flex-wrap justify-center px-6 m-auto mt-10">
            <section class="div text-white my-6">
                <p class="font-bold text-3xl m-6">
                    Opis miasta
                </p>
                <p>
                    Kuźnia Raciborska to miasto wchodzące w skład powiatu raciborskiego w województwie śląskim. Zostało ono założone w XVII w., a prawa miejskie otrzymało w 1967 r.
                    Kuźnia Raciborska ma 31,75 km² powierzchni. W 2016 roku liczba ludności wynosiła 5409 osób, a gęstość zaludnienia 171,8 os./km²
                </p>
            </section>
            <section class="text-white my-6">
                <p class="gallery-title font-bold text-3xl m-6">Galeria</p>
                <div class="gallery grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-5 place-items-center">
                    <div class="gallery-item" v-for="(image, index) in images" :key="index">
                        <img :src="image.imgToShow" alt="" @click="openModal(images[index].img, index)" class="cursor-pointer rounded-lg">
                    </div>
                </div>
            </section>
        </div>
        
    <modal :show="showModal" :index="imageData.index" :length="images.length" @close="showModal = false" 
        @decrement="decrement" @increment="increment">
    <template #body class="max-h-screen">
        <img :src="imageData.image" alt="" style="height:80vh" class="bg-cover">
    </template>
    <template #footer>
        {{imageData.index +1}}/{{images.length}}
    </template>
    </modal>
        
    </div>
    

</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Modal from './Modal.vue'

export default {
    components: {
        Modal
    },
    data() {
        return {
            showModal: false,
            imageData: {index: 0, image:null},
            images: [
                { img: require('../assets/duze/20220416_145544.jpg'), imgToShow: require('../assets/male/20220416_145544.jpg') },
                { img: require('../assets/duze/plac.jpg'), imgToShow: require('../assets/male/plac.jpg') },
                { img: require('../assets/duze/plac2.jpg'), imgToShow: require('../assets/male/plac2.jpg') },
                { img: require('../assets/duze/przedszkole1.jpg'), imgToShow: require('../assets/male/przedszkole1.jpg') },
                { img: require('../assets/duze/przedszkole2.jpg'), imgToShow: require('../assets/male/przedszkole2.jpg') },
                { img: require('../assets/duze/szkola.jpg'), imgToShow: require('../assets/male/szkola.jpg') },
                { img: require('../assets/duze/moksir.jpg'), imgToShow: require('../assets/male/moksir.jpg') },
                { img: require('../assets/duze/lotnisko.jpg'), imgToShow: require('../assets/male/lotnisko.jpg') },
                { img: require('../assets/duze/lotnisko2.jpg'), imgToShow: require('../assets/male/lotnisko2.jpg') },
                { img: require('../assets/duze/historia.jpg'), imgToShow: require('../assets/male/historia.jpg') },
                { img: require('../assets/duze/historia2.jpg'), imgToShow: require('../assets/male/historia2.jpg') },
                { img: require('../assets/duze/kosciol2.jpg'), imgToShow: require('../assets/male/kosciol2_galeria.jpg') },
                { img: require('../assets/male/pozar.jpeg'), imgToShow: require('../assets/male/pozar.jpeg') },
                { img: require('../assets/male/pozar2.jpg'), imgToShow: require('../assets/male/pozar2.jpg') },
                { img: require('../assets/duze/pkp.jpeg'), imgToShow: require('../assets/male/pkp.jpeg') },
                { img: require('../assets/duze/krzyz.jpg'), imgToShow: require('../assets/male/krzyz_galeria.jpg') },
                { img: require('../assets/duze/wieza.jpg'), imgToShow: require('../assets/male/wieza_galeria.jpg') },
                { img: require('../assets/duze/nepomucen.jpg'), imgToShow: require('../assets/male/nepomucen_galeria.jpg') },
            ],
        }
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger)
        
        var tl = gsap.timeline()
        this.gsap = tl.from('.background1', { y:'+=1000', duration: 0.75 })
            .to('.title', { clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', y:0, delay: 0.3})


        const div = gsap.fromTo('.div>p',{ y:'+=200', opacity: 0 }, { y:0, opacity:1, duration:1.5, stagger: .5, scrollTrigger:{
            trigger: '.div',
            start: 'top 80%',
            toggleActions: "play none none reverse",
            // markers:true
        }})

        const galleryTitle = gsap.fromTo('.gallery-title',{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .5, scrollTrigger:{
            trigger: '.gallery',
            start: 'top 80%',
            toggleActions: "play none none reverse",
            // markers:true
        }})
        const gallery = gsap.fromTo('.gallery>.gallery-item',{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .25, scrollTrigger:{
            trigger: '.gallery',
            start: 'top 80%',
            toggleActions: "play none none reverse",
            // markers:true
        }})
        
        galleryTitle.scrollTrigger.refresh()
        gallery.scrollTrigger.refresh()
        div.scrollTrigger.refresh()
    },
    methods: {
        openModal(image, index) {
            this.imageData = { index:index, image:image }
            this.showModal = true
        },
        decrement() {
            this.imageData.index = this.imageData.index - 1
            this.imageData.image = this.images[this.imageData.index].img
        },
        increment() {
            this.imageData.index = this.imageData.index + 1
            this.imageData.image = this.images[this.imageData.index].img
        }
    },
}
</script>

<style>
.background1 {
    background: url('../assets/duze/background.webp');
    background-size: cover;
    height: 92vh;
}
.container {
    max-width: 1200px;
    margin: auto;
}
.letter:hover{
   transform: scale(1.2);
}
</style>