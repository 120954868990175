<script>
export default {
  props: {
    show: Boolean,
    index: Number,
    length: Number
  }
}
</script>

<template>
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
          <button class="absolute top-1/2 text-5xl font bold mx-10 cursor-pointer" 
            :class="index == 0 ? 'text-gray-500 cursor-not-allowed' : null" :disabled="index == 0"
            @click="$emit('decrement')">&lt;</button>
          <button class="absolute right-0 top-1/2 text-5xl font bold mx-10 cursor-pointer disabled"
            :class="index+1 == length ? 'text-gray-500 cursor-not-allowed' : null" :disabled="index+1 == length"
            @click="$emit('increment')">&gt;</button>
        <div class="modal-container w-2/3 h-5/6 grid place-items-center">
        <div>
          <div class="modal-header">
            <div class="float-right flex font-bold text-3xl my-2 mx-3 cursor-pointer" @click="$emit('close')">X</div>
            <slot name="header"></slot>
            <div class="h-5 w-full"></div>
          </div>

          <div class="modal-body mt-10 grid place-items-center">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer grid place-items-center">
            <slot name="footer">
            </slot>
          </div>
        </div>

        </div>
      </div>
    </div>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  /* width: 300px; */
  margin: 0px auto;
  /* padding: 20px 30px; */
  background-color: transparent;
  border-radius: 2px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  /* margin: 0 0; */
}

.modal-default-button {
  float: right;
}

</style>