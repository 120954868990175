<template>
  <div>
      <nav class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center" 
      :class="showMenu ? 'h-screen' : null">
        <div class="flex items-center justify-between">
          <router-link to="/" class="logo text-xl font-bold text-white md:text-2xl">
            <img src="../assets/logo-strona-kuznia.png" alt="logo">
          </router-link>
          
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="menu-button text-white hover:text-gray-400 focus:outline-none focus:text-gray-400 ">
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <ul :class="showMenu ? 'flex' : 'hidden'" 
        class="flex-col mt-8 space-y-4 h-3/4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-8 md:mt-0 w-full md:w-1/2">
          <li>
            <router-link to="/" class="text-sm font-bold text-white" @click="showMenu = false">Strona główna</router-link>
          </li>
          <li>
            <router-link to="/historia" class="text-sm font-bold text-white" @click="showMenu = false">Historia</router-link>
          </li>
          <li>
            <router-link to="/zabytki" class="text-sm font-bold text-white" @click="showMenu = false">Zabytki</router-link>
          </li>
          <li>
            <router-link to="/miejsca" class="text-sm font-bold text-white" @click="showMenu = false">Miejsca</router-link>
          </li>
          <li>
            <router-link to="/gmina" class="text-sm font-bold text-white" @click="showMenu = false">Gmina</router-link>
          </li>
        </ul>
      </nav>
  </div>
</template>
<script>
import { gsap } from 'gsap'
export default {
  data() {
      return {
          showMenu: false,
      }
  },
  mounted() {
    var tl = gsap.timeline()
    

      installMediaQueryWatcher("(min-width: 750px)", (matches) => {

    if (matches) {   
      tl.from('.logo', {x:'-=500', delay: 1.25})
      .from('nav>ul>li', {y:'-=70', stagger: 0.25})
    } 
    });

    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
      var mql = window.matchMedia(mediaQuery);
      mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
      layoutChangedCallback(mql.matches);
    }
  }
}
</script>

<style>
nav ul {
  width: 40vw;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
nav ul li a {
  color: black;
  text-decoration: none;
  transition: .5s;
}
nav ul li a:hover {
  color: rgb(185, 200, 233);
}

nav li .router-link-active {
  color: rgb(122, 130, 209);
}
</style>