<template>
  <div class="mt-10">
    <div class="container px-6">
      <h1 class="grid place-items-center font-bold text-3xl m-4">Gmina</h1>
      <section class="section-one py-5">
        <h2 class="font-bold text-2xl m-6">Opis gminy</h2>  
        <p>
          Kuźnia Raciborska to gmina miejsko-wiejska w Polsce z siedzibą w mieście Kuźnia Raciborska, która wchodzi w skład powiatu raciborskiego w województwie śląskim. W latach 1975–1998 gmina administracyjnie należała do województwa katowickiego.
          Gmina Kuźnia Raciborska leży w górnym biegu Odry i dolnym Rudy, swym zasięgiem obejmuje obszar Kotliny Raciborskiej oraz północno-zachodniej części Płaskowyżu Rybnickiego, należącego do Wyżyny Śląskiej.
        </p>
      </section>
      <section class="section-two py-5">
        <h2 class="font-bold text-2xl m-6">Skład gminy</h2>
        <p>
          Po reformie administracyjnej (styczeń 1999r.) w skład gminy wchodzą:
          <ul class="list-disc ml-4">
            <li>Kuźnia Raciborska</li>
            <li>Budziska</li>
            <li>Siedliska</li>
            <li>Turze</li>
            <li>Ruda</li>
            <li>Rudy</li>
            <li>Ruda Kozielska</li>
            <li>Jankowice</li>
          </ul>
        </p>
      </section>
      <div></div>
    </div>
    <div class="mt-20">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81714.0573734989!2d18.2990355959912!3d50.20667471481654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4711408880cc4b1f%3A0x940ba46ad640fb3e!2sKu%C5%BAnia%20Raciborska!5e0!3m2!1spl!2spl!4v1649699543504!5m2!1spl!2spl" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  mounted() {
    const sections = document.querySelectorAll('section')
    sections.forEach(section => {
      gsap.fromTo(section.children,{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
        trigger: section,
        start: 'top 73%',
        toggleActions: "play none none reverse",
      }})
    })
  }
}
</script>

<style>

</style>