import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import './index.css'

import App from './App.vue'
import Home from './components/Home.vue'
import Historia from './components/Historia.vue'
import Zabytki from './components/Zabytki.vue'
import Miejsca from './components/Miejsca.vue'
import Gmina from './components/Gmina.vue'

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path: '/', 
            name: 'home',
            component: Home,
        },
        {
            path: '/historia',
            name: 'historia',
            component: Historia
        },
        {
            path: '/zabytki',
            name: 'zabytki',
            component: Zabytki
        },
        {
            path: '/miejsca',
            name: 'miejsca',
            component: Miejsca
        },
        {
            path: '/gmina',
            name: 'gmina',
            component: Gmina
        },
    ]
})

createApp(App).use(router).mount('#app')