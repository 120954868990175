<template>
    <div class="container px-6">
        <h1 class="font-bold text-3xl grid place-items-center m-4">Zabytki</h1>
        <section class="section-one py-8">
            <div>
                <h2 class="font-bold text-2xl p-5">Kościół pod wezwaniem św. Marii Magdaleny</h2>
            </div>
            <div>
                <p>
                    Z historią Kuźni Raciborskiej nierozerwalnie związana jest historia kuźniańskiego kościoła. Zaledwie pięć lat po założeniu osady hutniczej, tzn. w roku 1646, hrabia Jerzy von Oppersdorf wybudował dla tutejszych osadników kaplicę pod wezwaniem św. Marii Magdaleny. Należała ona do rozległej parafii markowickiej. Poważniejszej rozbudowy kościółka dokonano w 1868 r. Dopiero w 1892 roku lokakia w Kuźni Raciborskiej stała się samodzielną parafią. Podjęto więc starania o rozbudowę starego, drewnianego kościółka, które zostały uwieńczone sukcesem w roku 1902.Uroczysta konsekracja kościoła przez ks. biskupa sufragana Augustina nastąpiła dopiero w 1917 roku. Kościół parafialny p.w. św. Marii Magdaleny jest świątynią neoromańską z elementami neogotyckimi. Architektoniczny ołtarz główny z obrazem św. Marii Magdaleny flankowany jest rzeźbami św. Jana Nepomucena i Dominika
                </p>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 p-5 place-items-center">
                <img src="../assets/male/kosciol2.jpg" alt="kościół" class="img-right w-7/12 rounded-lg">
                <img src="../assets/male/20220416_145544.jpg" alt="kościół2" class="img-left w-10/12 rounded-lg">
            </div>
        </section>
        <section class="section-two py-8">
            <div>
                <h2 class="font-bold text-2xl p-5">Kapliczka św. Jana Nepomucena</h2>
            </div>
            <div>
                <p>
                    Kapliczka św. Jana, stojąca na placu Mickiewicza, pochodzi z połowy XIX w. i jest najstarszym obiektem w Kuźnii Raciborskiej. Jest to drewniana kapliczka nakryta dachem gontowym, która po ostatnim remoncie została położona na ceglanej podmurówce. Figura świętego, znajdująca się w środku, jest wykonana z kamienia piaskowego i również została postawiona na ceglanej podstawie.
                </p>
            </div>
            <div class="grid place-items-center mt-3 kapliczka">
                <img src="../assets/male/nepomucen.jpg" alt="kapliczka-nepomucena" class="w-1/4 rounded-lg">
            </div>
        </section>
        <section class="section-three py-8">
            <div>
                <h2 class="font-bold text-2xl p-5">Pomnik zwycięstwa</h2>  
            </div>
            <div>
                <p>Na Placu Zwycięstwa, przy ulicy Kościelnej, znajduje się Pomnik Zwycięstwa. Odsłonięto go 5 września 1970 roku w celu uczczenia XXV rocznicy zwycięstwa nad faszyzmem.</p>    
            </div>
            <div class="grid place-items-center m-3">
                <img src="../assets/male/plac.jpg" alt="plac" class="section-three-img w-1/2 rounded-lg">
            </div>
        </section>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
    mounted() {
        gsap.registerPlugin(ScrollTrigger)

        gsap.from('.img-left', { x:'+=300', duration:2.3})
        gsap.from('.img-right', { x:'-=300', duration:2.3})

        const sections = document.querySelectorAll('section')
        sections.forEach(section => {
            gsap.fromTo(section.children,{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
                trigger: section,
                start: 'top 73%',
                toggleActions: "play none none reverse",
            }})
        })
    }
}
</script>

<style>

</style>