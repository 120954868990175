<template>
    <div class="p-6">
        <div class="grid grid-cols-1 lg:grid-cols-3 place-items-center py-5">
            <div class="my-5 lg:my-0">
                <p class="mb-5 text-lg font-bold">Podstawowe technologie użyte na stronie:</p>
                <ul class="px-3">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>Javascript</li>
                </ul>
            </div>
            <div class="my-5 lg:my-0">
                <p class="mb-5 text-lg font-bold">Zaawansowane technologie użyte na stronie:</p>
                <ul class="px-3">
                    <li>Vue.js</li>
                    <li>Tailwind</li>
                    <li>Gsap</li>
                </ul>
            </div>
            <div class="my-5 lg:my-0">
                <p class="mb-5 text-lg font-bold">Aplikacja mobilna</p>
                <ul class="px-3">
                    <li>
                        <a href="/app-debug.apk" download>Pobierz aplikację mobilną na Androida</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="grid place-items-center mt-8">
            <p class="text-gray-400">
                &copy; Szymon Tykierka {{ date }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            date: null
        }
    },
    mounted() {
        this.date = new Date().getFullYear()
    }
}
</script>

<style>
</style>