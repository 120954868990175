<template>
    <div class="container px-6">
        <h1 class="font-bold text-3xl grid place-items-center m-4">Historia</h1>
        <section class="section-one py-5">
            <h2 class="font-bold text-2xl p-5">Historia gminy</h2>
            <p>
                Dzieje miejscowości wchodzących w skład gminy Kuźnia Raciborska, układały się w sposób odmienny. Losy Rud, Rudy Kozielskiej i Jankowic były ściśle związane z klasztorem cystersów rudzkich. Wsie nadodrzańskie były słabo związane z księstwem raciborskim. Najpóźniej powstała sama Kuźnia Raciborska jako samodzielna osada przemysłowa.
                Przez lata ziemia raciborska wchodziła w skład piastowskiego księstwa raciborsko-opolskiego. Od 1840r. Rudy stają się siedzibą księcia raciborskiego. Dobra klasztorne były w posiadaniu rodu Hohenloche-Waldenburg-Schilingfürst do 1945r.
                Wsie zamieszkiwała ludność polska przez lata opierająca się germanizacji. Centrum ruchu narodowego były Budziska, gdzie istniała świetlica polska. Później powstały polskie szkoły mniejszościowe w Budziskach, Siedliskach i Turzu.
                18 maja 1945r. utworzono gminę z miejscowościami: Kuźnia Raciborska, Turze, Budziska, Siedliska, Ruda, Ciechowice, Solarnia. Na jej terenie mieszkało 1530 osób. 1 stycznia 1967r. Kuźnia Raciborska otrzymała prawa miejskie. W 1973r. powstał Urząd Miasta i Gminy Kuźnia Raciborska, a cztery lata później do gminy zostały włączone Rudy, Ruda Kozielska i Jankowice.
            </p>
            <div class="grid p-5 place-items-center">
                <img src="../assets/male/historia.jpg" alt="historia" class="w-3/4 rounded-lg">
                <p class="text-gray-500">źródło: https://polska-org.pl/7891415,foto.html?idEntity=7891419</p>
            </div>
        </section>
        <section class="section-two py-5">
            <h2 class="font-bold text-2xl p-5">Historia miasta</h2>
            <p>
                W 1641r. zarządca dawnego księstwa raciborskiego Octavian Seger von Segenberg zakłada na karczowisku leśnym "Potępa" osiedle hutnicze Segenberg. Wybudowano kuźnicę do wytapiania Rudy, powstał Tartak i warsztat wyrobu gontów. Rok później powstaje druga kuźnia "Naślepiów". Dla osadników hrabia Jerzy Oppersdorf w 1642r. wybudował kaplicę pw. św. Marii Magdaleny. Proboszcz z Markowic został zobowiązany do odprawienia w ciągu roku 5 nabożeństw. W 1746r. uruchomiono nowoczesny piec hutniczy. Na rynku osady hutniczej staje figura św. Jana Nepomucena, ufundowana przez hr. Sobecka. Według spisu z 1747r. osada liczyła 274 mieszkańców, na zachód od wsi zlokalizowany był folwark. W 1845 r. zarządca książęcy A. Schoenawa uruchamił nowoczesny zakład - hutę "Nadzieja", obejmującą walcownię, fabrykę śrub i wyrób osi.
                Rozwój osady nastąpił wraz z uruchomieniem linii kolejowej Kędzierzyn-Racibórz. W 1883r. zbudowano nową szkołę. Liczba mieszkańców wynosiła 1072, a w Kuźni Nowej - 655. Do zakładów hutniczych zaczyna się wprowadzać nową technologię: w 1885r. wygaszono ostatni piec fryszerski, a na jego miejscu w 1893r. wybudowano odlewnię żeliwa. Ok. 1850r. oddano do użytku tartak parowy Burschika i Manna (na terenie obecnej betoniarni). W 1904 r. wielki pożar niszczy zachodnią część osady. Tuż przed pierwszą wojną światową w 1913r. Kuźnia liczy 2176 mieszkańców. Trzy lata później spłonął tartak Burschik - Mann, na jego miejscu firma z Berlina uruchamia nowy tartak. W 1944r. Kuźnia liczy 3530 mieszkańców. 28 stycznia do Kuźni Raciborskiej wkroczyła Armia Radziecka, podpaliła ośrodek młodzieżowy, szkołę i dwie restauracje. W 1949r. rozpoczęto budowę osiedla mieszkaniowego dla pracowników Raciborskiej Fabryki Wyrobów Metalowych. 1 stycznia 1967r. Kuźnia Raciborska otrzymała prawa miejskie.
            </p>
            <div class="grid place-items-center mt-5">
                <img src="../assets/male/historia2.jpg" alt="historia2" class="w-3/4 rounded-lg">
                <p class="text-gray-500">źródło: https://polska-org.pl/9362982,foto.html?idEntity=6979729</p>
            </div>
        </section>
        <section class="section-three py-5">
            <h2 class="font-bold text-2xl p-5">Największy pożar w europie</h2>
            <p>
                26 sierpnia 1992 roku temperatura przekraczała 30 stopni Celsjusza, wszędzie panowała susza przez brak deszczu od maja. Pożar najprawdopodobniej został wywołany przez iskrę z kół hamującego pociągu. Pożar szybko się rozprzestrzeniał poprzez silny wiatr. Na miejsce wzywano kolejne jednostki z województwa katowickiego i opolskiego. Pożar po godzinie obejmował 80 hektarów, a po dwóch 180.
                29 sierpnia pożar obejmował 6 tysięcy hektarów. Pożar próbowały ugasić jednostki z całego kraju. Akcja gaśnicza trwałą 26 dni, a pożar próbowało ugasić ok. 10 tys. osób. Ostatecznie spłonęło prawie 10 tys. hektarów i był to największy pożar w europie.
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 place-items-center m-3">
                <img src="../assets/male/pozar.jpeg" alt="" class="pozar1 w-3/4 rounded-lg">
                <img src="../assets/male/pozar2.jpg" alt="" class="pozar2 w-3/4 rounded-lg">
            </div>
            <p class="text-gray-500 text-center">źródło: https://www.lasy.gov.pl/pl/informacje/aktualnosci/28-lat-po-pozarze-w-kuzni-raciborskiej</p>
        </section>
        <section class="section-four py-5 pb-40">
            <h2 class="font-bold text-2xl p-5">Daty</h2>
            <p>
                <ul as="p">
                    <li>1641 - zarządca posiadłości ziemskiej Raciborza Octavian von Segenberg na karczowisku leśnym zwanym "Potępą" założył osiedle hutnicze, występujące wówczas pod nazwą Segenberg. Powstaje tartak i warsztat wyrobu gontów,</li>
                    <li>1642 - powstaje druga kuźnica nazwana "Nieślepiów"</li>
                    <li>1646 - hrabia Jerzy Oppersdorf buduje dla osadników smolarzy i hutników kaplicę,</li>
                    <li>1746 - uruchomienie pierwszego pieca hutniczego,</li>
                    <li>1747 - hrabia Sobeck funduje pomnik św. Nepomucena. Kuźnia ma 274 mieszkańców i 1 folwark,</li>
                    <li>1883 - zbudowano nową szkołę w Kuźni</li>
                    <li>1945 - Armia Czerwona zdobyła Kuźnię Raciborską</li>
                    <li>1946 - pracę rozpoczęła Fabryka Wyrobów Metalowych "Rafo", aktualnie "Rafamet"</li>
                </ul>                
            </p>  
        </section>
        
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
    mounted() {
        gsap.registerPlugin(ScrollTrigger)

        const sections = document.querySelectorAll('section')
        sections.forEach(section => {
            const secs = gsap.fromTo(section.children,{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
                trigger: section,
                start: 'top 73%',
                toggleActions: "play none none reverse",
            }})
            secs.scrollTrigger.update()
        })
        // gsap.fromTo('.pozar1',{ x:'-=200', opacity: 0 }, { x:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
        //     trigger: '.pozar1',
        //     start: 'top 70%',
        //     toggleActions: "play none none reverse",
        // }})
        // gsap.fromTo('.pozar2',{ x:'+=90', opacity: 0 }, { x:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
        //     trigger: '.pozar2',
        //     start: 'top 70%',
        //     toggleActions: "play none none reverse",
        //     // markers:true
        // }})

        

    }
}
</script>

<style>

</style>