<template>
    <div class="container px-6">
        <h1 class="font-bold text-3xl grid place-items-center m-4">Miejsca</h1>
       <section class="section-one">
            <h2 class="font-bold text-2xl m-6">Rafamet</h2>
            <p>W 1846 roku wraz z uruchomieniem linii kolejowej Berlin-Wiedeń przebiegającej przez Kuźnię Raciborską, bezpośrednio obok stacji powstała pierwsza huta żelaza - "Nadzieja".
                W drugiej połowie XIX wieku fabryka produkowała różne wyroby dla kolei, m.in. śruby i osie do zestawów kołowych.
                Na początku XX wieku Zakłady w Kuźni Raciborskiej zostały wykupione przez Wilhelma Hegenscheidt i od tego czasu datuje się początek produkcji obrabiarek specjalnych do obróbki zestawów kołowych.
                Po ustaniu działań II wojny światowej powstała fabryka Obrabiarek "Rafamet". Już w 1947 r. wyprodukowano pierwsze obrabiarki dla warsztatów kolejowych w Pruszkowie, a w 1948 r. rozpoczął się eksport rafametowskich maszyn, który miał decydujący wpływ na rozwój zakładu.
                W 1964 roku "RAFAMET" uzyskuje prawo prowadzenia samodzielnej działalności eksportowej i importowanej. W 2021 roki Agencja Rozwoju Przemysłu S.A. z siedzibą w Warszawie nabywa 92,27% akcji RAFAMET i staje się największym akcjonariuszem Spółki.
            </p>
            <div class="grid place-items-center m-3">
                <img src="../assets/duze/rafamet.jpg" alt="rafamet" class="w-2/3 rounded-lg">
            </div>
       </section>
       <section class="section-two">
            <h2 class="font-bold text-2xl m-6">Szkoła Podstawowa</h2>
            <p></p>
           <div class="grid place-items-center">
               <img src="../assets/duze/szkola.jpg" alt="szkola" class="w-2/3 rounded-lg">
           </div>
       </section>
       <section class="section-three">
            <div>
                <h2 class="font-bold text-2xl m-6">Przedszkola</h2>
            </div>
            <div>
                <p>Przedszkole nr 1 w Kuźni Raciborskiej powstało w 1946 roku i jest położone w pobliżu Urzędu Miasta, Urzędu Pocztowego i Posterunku Policji. Funkcjonuje w nim 5 grup i posiada łącznie 125 miejsc dla dzieci w wieku od 3 do 6 lat.</p>
                <p>Przedszkole nr 2 w Kuźni Raciborskiej jest położone wśród bloków osiedla Kuźni Raciborskiej przy ulicy Westerplatte 1. Jest ono otoczone dużym ogrodem z dwoma placami zabaw. W przedszkolu znajdują się cztery sale do zajęć. Czynne są cztery oddziały, do których uczęszcza 100 dzieci.</p>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 place-items-center m-3">
                <img src="../assets/duze/przedszkole1.jpg" alt="przedszkole1" class="przedszkole1 w-5/6 rounded-lg">
                <img src="../assets/duze/przedszkole2.jpg" alt="przedszkole2" class="przedszkole2 w-5/6 rounded-lg">
            </div>
       </section>
       <section class="section-four">
                <h2 class="font-bold text-2xl m-6">Miejski Ośrodek Kultury, Sportu i Rekreacji (MOKSiR)</h2>
                <p>W MOKSiR, który znajduje się przy ulicy Klasztornej, prowadzone są zajęcia o różnej tematyce między innymi taneczne, teatralne, sportowe i komputerowe. Promuje też dokonania artystyczne lokalnych twórców kultury, zespołów artystycznych i inicjatyw kulturalnych na terenie gminy.</p>
            <div class="grid place-items-center m-3">
                <img src="../assets/duze/moksir.jpg" alt="moksir" class="w-2/3 rounded-lg">
            </div>
       </section>
       <section class="section-five">
            <h2 class="font-bold text-2xl m-6">Stacja kolejowa</h2>
            <p>Stacja kolejowa w Kuźni Raciborskiej jest stacją z typowym rozwiązaniem peronów bocznych oraz przejściem nad ziemią. W 2017 r. stacja obsługiwała 100 - 150 pasażerów na dobę. </p>
            <div class="grid place-items-center m-3">
                <img src="../assets/duze/pkp.jpeg" alt="pkp" class="w-1/4 rounded-lg">
            </div>
       </section>
       <section class="section-six">
           <h2 class="font-bold text-2xl m-6">Lotnisko</h2>
           <p>Po największym pożarze w europie, który wystąpił w Kuźni Raciborskiej w 1992 r. wybudowano lotnisko, aby zapobiec następnym pożarom.</p>
           <div class="grid grid-cols-1 md:grid-cols-2 place-items-center m-3 gap-3">
               <img src="../assets/duze/lotnisko.jpg" alt="" class="rounded-lg w-3/4">
               <img src="../assets/duze/lotnisko2.jpg" alt="" class="rounded-lg w-3/4">
           </div>
       </section>
       <section class="section-six">
           <h2 class="font-bold text-2xl m-6">Wieża obserwacyjna i krzyż</h2>
           <p>Po pożarze wybudowano również wieżę obserwacyjną. Krzyż wybudowano w miejscu, gdzie znaleziono osoby, który zginęły w pożarze.</p>
           <div class="grid grid-cols-1 md:grid-cols-2 place-items-center m-3 gap-3">
               <img src="../assets/duze/wieza.jpg" alt="" class="rounded-lg w-1/2">
               <img src="../assets/duze/krzyz.jpg" alt="" class="rounded-lg w-1/2">
           </div>
       </section>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
export default {
    mounted() {

        gsap.registerPlugin(ScrollTrigger)

        const sections = document.querySelectorAll('section')

        sections.forEach(section => {
            gsap.fromTo(section.children,{ y:'+=100', opacity: 0 }, { y:0, opacity:1, duration:1, stagger: .55, scrollTrigger:{
                trigger: section,
                start: 'top 73%',
                toggleActions: "play none none reverse",
            }})
        })

        

        installMediaQueryWatcher("(min-width: 750px)", (matches) => {

    if (matches) {   
      gsap.fromTo('.przedszkole1',{ x:'-=150', opacity: 0 }, { x:0, opacity:1, duration:1.5, stagger: .5, scrollTrigger:{
            trigger: '.przedszkole1',
            start: 'top 75%',
            toggleActions: "play none none reverse",
            // markers:true
        }})
        gsap.fromTo('.przedszkole2',{ x:'+=150', opacity: 0 }, { x:0, opacity:1, duration:1.5, stagger: .5, scrollTrigger:{
            trigger: '.przedszkole2',
            start: 'top 75%',
            toggleActions: "play none none reverse",
            // markers:true
        }})
    } 
    });

    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
      var mql = window.matchMedia(mediaQuery);
      mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
      layoutChangedCallback(mql.matches);
    }
  }
        
}

</script>

<style>

</style>